<template>
  <div>
    <hb-tooltip>
      <template v-slot:item>
        <div class="multi-line" v-if="discount_effects?.length > 0">
          <span>{{ effectCount }} {{ effect.type }}</span>
          <span
            :class="{ dashed: effectCount?.length }"
            :style="{ color: '#8E8E8F' }"
            >{{ getLine }}</span
          >
        </div>
        <div class="multi-line" v-if="discount?.name">
          <span :class="{ dashedDiscount: discount.description }">{{ discount.name }}</span>
          <span
            class="pt-1"
            :style="{ color: '#8E8E8F' }"
            >{{ discount.description }}</span
          >
        </div>
      </template>

      <template v-slot:body>
        <div class="promo-tooltip pa-2">
          <v-row class="px-3">
            <hb-icon mdi-code="mdi-bullhorn" small color="#8DE3E3" />
            <span
              class="hb-font-header-3-medium pl-2"
              style="color: #8de3e3; text-transform: capitalize"
              >Discount</span
            >
          </v-row>
          <div v-if="discount_effects?.length > 0">
            <span class="hb-font-body-medium">Effects</span>
            <ul class="list">
              <li v-for="(effectItem, index) in discount_effects" :key="index">
                <div class="d-flex" v-if="index < 10">
                  <span class="dot mr-2">&#8226;</span>
                  <span>{{ formatEffect(effectItem) }}</span>
                </div>
              </li>
              <div class="d-flex justify-end" v-if="discount_effects.length > 10">
                +{{ discount_effects.length - 10 }} more
              </div>
            </ul>
          </div>
          <div v-if="discount?.name">
            <div class="hb-font-body-medium">{{ discount.name }}</div>
            <div class="divider"></div>
            <span class="tooltip-description mt-1">{{
              discount.description
            }}</span>
          </div>
        </div>
      </template>
    </hb-tooltip>
  </div>
</template>

<script>
import { capitalizeFirstLetter } from "@/utils/common";
export default {
  name: "DiscountEffects",
  data() {
    return {
      effect: {},
      effectCount: "",
    };
  },
  props: {
    discount_effects: {
      type: Array,
      default: () => [],
    },
    discount: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getLine() {
      let line = "";
      if (this.effect?.item) line += this.effect?.item + ":";
      if (this.effect?.value_type === "dollar")
        line += ` $${this.effect.value} Off`;
      else if (this.effect?.value_type === "fixed")
        line += ` Fixed Rate: $${this.effect.value}`;
      else if (this.effect?.value_type === "percent")
        line += ` ${this.effect.value}% Off`;
      return line;
    },
  },
  methods: {
    formatEffect(effectItem) {
      const { on, amount } = effectItem;
      let product_name = effectItem.product_name
        ? ` - ${effectItem.product_name}`
        : "";
      switch (amount.type) {
        case "fixed":
          return `${capitalizeFirstLetter(on)}${
            on === "merchandise" ? product_name : ""
          } - Fixed Rate: $${amount.value}`;
        case "dollar":
          return `${capitalizeFirstLetter(on)}${
            on === "merchandise" ? product_name : ""
          } - $${amount.value} Off`;
        case "percent":
          return `${capitalizeFirstLetter(on)}${
            on === "merchandise" ? product_name : ""
          } - ${amount.value}% Off`;
        default:
          return "";
      }
    },
  },
  created() {
    if (this.discount_effects && this.discount_effects?.length > 0) {
      let index =
        this.discount_effects.findIndex((effect) => effect.on === "rent") !== -1
          ? this.discount_effects.findIndex((effect) => effect.on === "rent")
          : 0;
      this.effect = {
        type: capitalizeFirstLetter(this.discount_effects[index].on) || "",
        item: this.discount_effects[index]?.product_name || "",
        value: this.discount_effects[index]?.amount?.value || "",
        value_type: this.discount_effects[index]?.amount?.type || "",
      };
    }
  },
  watch: {
    discount_effects: {
      handler(value) {
        this.effectCount = value?.length > 1 ? `(${value.length})`: ``;
      },
      immediate: true
    }
  },
};
</script>
<style lang="scss" scoped>
.multi-line {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    &.dashed {
      margin-top: 0;
      border-top: 1px dashed #637381;
    }
    &.dashedDiscount {
      margin-bottom: 0;
      border-bottom: 1px dashed #637381;
    }
  }
}

.divider {
  width: 100%;
  border-top: 2px dashed #ffffff;
  padding-bottom: 4px;
}

.promo-tooltip {
  background-color: #334450;
  color: white;
  box-shadow: 0px 8px 18px rgba(71, 84, 116, 0.2);
  max-width: 300px;
  // min-height: 424px;
  border-radius: 5px;
  .list {
    list-style: none;
  }
}
</style>
