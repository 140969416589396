<template>
  <div>
    <hb-empty-state
      v-if="!overrides.length"
      message="You can set up overrides that will take effect over the promo plan defaults."
      class="ma-4"
    >
      <template v-slot:buttons>
        <hb-menu v-if="!overrides.length" class="d-flex justify-center">
          <template v-slot:menu-activator>
            <hb-btn color="primary" append-icon="mdi-menu-down">
              Add Override
            </hb-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(type, index) in Object.keys(overrideTypes)"
              :key="index"
              @click="addOverride(type)"
              :ripple="false"
            >
              <v-list-item-title>{{ overrideTypes[type] }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </hb-menu>
      </template>
    </hb-empty-state>
    <div v-else>
      <div class="px-3 pt-3">
        <hb-notification v-model="showGuide" type="guidance" not-dismissable>
          Arrange overrides by priority from top to bottom.
        </hb-notification>
      </div>
      <draggable :list="overrides" @end="setSort()">
        <div v-for="(item, i) in overrides" :key="i + item.type">
          <v-list-item class="px-0">
            <v-list-item-icon class="mr-n1">
              <hb-btn icon tooltip="Drag" active-state-off>
                mdi-drag-custom
              </hb-btn>
            </v-list-item-icon>
            <v-list-item-content class="pt-0">
              <v-expansion-panels
                elevation="0"
                v-model="panel[i + 1]"
                :key="i + item.type"
              >
                <hb-expansion-panel>
                  <template v-slot:title>
                    {{ overrideTypes[item.type] }}
                  </template>
                  <template v-slot:content>
                    <div class="promo-setting-content d-flex pa-4">
                      <span>
                        If {{ overrideTypes[item.type].toLowerCase() }} is
                      </span>
                      <hb-select
                        v-model="item.comparison"
                        width="70px"
                        :items="comparisons"
                        item-value="value"
                        item-text="key"
                        box
                        :clearable="false"
                        v-validate="'required'"
                        data-vv-as="Comparison"
                        :data-vv-name="`comparison_${item.type}`"
                        :error-messages="
                          errors.collect(`comparison_${item.type}`)
                        "
                      />
                      <hb-text-field
                        v-model="item.value"
                        placeholder="0"
                        box
                        small
                        v-validate="'required|integer|min_value:0'"
                        :data-vv-as="overrideTypes[item.type]"
                        :data-vv-name="`value_${item.type}`"
                        :error-messages="errors.collect(`value_${item.type}`)"
                      />
                      <div class="pr-1">
                        {{
                          item.type === "group-vacancy-count" ? "spaces, " : ""
                        }}apply
                      </div>
                      <div
                        :class="
                          item.type === 'group-vacancy-count'
                            ? 'pm-space-select'
                            : 'pm-select'
                        "
                      >
                        <hb-select
                          v-model="item.promotion_id"
                          :items="activePromotions"
                          item-value="id"
                          item-text="name"
                          placeholder="Select Promotion"
                          v-validate="'required'"
                          :clearable="false"
                          :data-vv-as="`Promotion for ${
                            overrideTypes[item.type]
                          }`"
                          :data-vv-name="`promotion_id_${item.type}`"
                          :error-messages="
                            errors.collect(`promotion_id_${item.type}`)
                          "
                        />
                      </div>
                    </div>
                  </template>
                </hb-expansion-panel>
              </v-expansion-panels>
            </v-list-item-content>
            <v-list-item-action class="ml-0 close-position mt-4 mr-1">
              <hb-btn
                icon
                tooltip="Close"
                mdi-code="mdi-close"
                @click="removeOverride(item, i)"
              />
            </v-list-item-action>
          </v-list-item>
        </div>
      </draggable>
      <div class="ml-9 mb-3">
        <hb-menu v-if="showAddOverride" class="d-flex">
          <template v-slot:menu-activator>
            <hb-btn color="secondary" append-icon="mdi-menu-down">
              Add Override
            </hb-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(type, index) in Object.keys(getUnusedOverrides)"
              :key="index"
              @click="addOverride(type)"
              :ripple="false"
            >
              <v-list-item-title>{{ overrideTypes[type] }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </hb-menu>
      </div>
    </div>
  </div>
</template>
<script>
import Draggable from "vuedraggable";
import { mapGetters } from "vuex";

export default {
  name: "CreatePlanOverrides",
  components: {
    Draggable,
  },
  mixins: [],
  props: {
    stepData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      panel: {
        1: [],
        2: [],
      },
      showGuide: true,

      draggableHeight: 0,
      overrides: [],
      overrideTypes: {
        "days-vacant": "Days Vacant",
        "group-vacancy-count": "Group Vacancy Count",
      },
      comparisons: [
        {
          key: "≥",
          value: ">=",
        },
        {
          key: "≤",
          value: "<=",
        },
        {
          key: ">",
          value: ">",
        },
        {
          key: "<",
          value: "<",
        },
        {
          key: "=",
          value: "=",
        },
        {
          key: "!=",
          value: "!=",
        },
      ],
    };
  },
  async mounted() {},
  computed: {
    ...mapGetters({
      getActivePromotions: "revManStore/getActivePromotions",
    }),
    activePromotions() {
      return this.getActivePromotions.filter(
        (promo) =>
          promo?.label === "promotion" &&
          !promo?.conditions?.includes("existing_tenants")
      );
    },
    getUnusedOverrides() {
      let result = {};
      Object.keys(this.overrideTypes).forEach((key) => {
        if (!this.overrides.some((o) => o.type === key)) {
          result[key] = this.overrideTypes[key];
        }
      });
      return result;
    },
    showAddOverride() {
      return this.overrides.length < 2;
    },
  },
  methods: {
    setHeight() {
      let fixedComponents = 184;
      this.draggableHeight = window.innerHeight - fixedComponents;
    },
    async submit() {
      this.$validator.errors.clear();
      const valid = await this.$validator?.validateAll();
      return valid
        ? {
            status: true,
            validatedData: {
              overrides: this.overrides,
            },
          }
        : {
            status: false,
            validatedData: {},
          };
    },
    setSort() {
      this.overrides = this.overrides.map((obj, index) => {
        return { ...obj, sort: index + 1 };
      });
    },
    removeOverride(data, index) {
      this.overrides.splice(index, 1);
    },
    addOverride(type) {
      let newOverride = {
        type: type,
        comparison: "=",
        value: "",
        promotion_id: "",
        sort: this.overrides.length + 1,
      };
      this.overrides.push(newOverride);
      this.panel[this.overrides.length] = 0;
    },
    resetData() {
      this.overrides = [];
      this.$validator.errors.clear();
    },
    removePromotion(index) {
      this.overrides[index].promotion_id = "";
    },
  },

  watch: {
    stepData: {
      handler(value) {
        this.overrides = value.overrides ?? this.overrides;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.promo-setting-content {
  gap: 5px;
  align-items: baseline;
}
.close-position {
  align-self: baseline;
}
.pm-select {
  max-width: 220px;
}
.pm-space-select {
  max-width: 200px;
}
</style>
