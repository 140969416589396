<template>
  <div class="mb-5">
    <div v-if="promo.value_tier_overrides">
      <hb-form
        v-for="(valueTier, idx) in promo.value_tier_overrides"
        :key="idx"
        :label="capitalizeFirstLetter(valueTier.tier_type)"
      >
        <div v-if="valueTier.promotion_id">
          <div class="pb-2">{{ capitalizeFirstLetter(valueTier.type) }}</div>
          <MultilinePromoViewer
            :wrapText="true"
            :promotion-id="valueTier.promotion_id"
          />
        </div>
        <div v-else>
          {{
            valueTier.type === "none"
              ? "No"
              : capitalizeFirstLetter(valueTier.type)
          }} Promotion
        </div>
      </hb-form>
    </div>
    <div v-else class="px-6 py-3">
      <hb-empty-state message="No promotion plan value tiers"></hb-empty-state>
    </div>
  </div>
</template>

<script>
import MultilinePromoViewer from "./MultilinePromoViewer.vue";
import { capitalizeFirstLetter } from "../../../utils/common";
export default {
  name: "PromotionPlanValueTiers",
  components: { MultilinePromoViewer },
  props: {
    promo: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  methods: {
    capitalizeFirstLetter(text) {
      return capitalizeFirstLetter(text);
    },
  },
};
</script>
