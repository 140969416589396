<template>
  <div class="promo-plan-list pa-6">
    <div
      class="d-flex align-center justify-space-between promo-plan-list-header pb-2 pr-1"
    >
      <span>Occupancy Tiers</span>
      <span>Promotions</span>
    </div>
    <v-divider></v-divider>
    <div class="promo-plan-content">
      <div v-for="(item, idx) in promoPlansWithRange" :key="idx">
        <div class="d-flex align-center justify-space-between py-2 mx-1">
          <span class="promo-occupancy">{{ item.min }}</span>
          <span v-if="!item.promotion_id" class="promo-detail">
            No Promotion
          </span>
          <MultilinePromoViewer
            v-else
            class="promo-detail"
            :promotion-id="item.promotion_id"
          />
        </div>
        <v-divider></v-divider>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";

import MultilinePromoViewer from "./MultilinePromoViewer.vue";

export default {
  name: "PromoPlanDefault",
  props: {
    promo: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  components: { MultilinePromoViewer },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getActivePromotions: "revManStore/getActivePromotions",
    }),
    activePromotions() {
      return this.getActivePromotions.filter(
        (promo) => promo?.label === "promotion"
      );
    },
    /**
     * Function to convert occupancy of each settings into a range
     *
     *  - The occupancy value will be updated to a string in the "<starting-occupancy>% - <ending-occupancy>%" form.
     *  - The 'starting occupancy' will be same as the occupancy value for all the settings
     *  - For the first setting, 100 will be taken as the 'ending occupancy'
     *  - For the subsequent settings, the occupancy value of the previous one will be taken as the 'ending occupancy'
     *
     * @returns {Array} list of setting with range converted occupancy value
     */
    promoPlansWithRange() {
      const rules = cloneDeep(this.promo.plan_default);
      const noOfRules = (rules ?? []).length;
      const promoPlansWithRange = [];
      for (let i = 0; i < noOfRules; i++) {
        const currRule = rules[i];
        let currOccupancy = currRule.min;
        const prevOccupancy =
          i == 0 ? "100" : this.promo.plan_default[i - 1]?.min;
        currRule.min = `${currOccupancy}% - ${prevOccupancy}%`;
        promoPlansWithRange.push(currRule);
      }
      return promoPlansWithRange;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.promo-occupancy {
  min-width: 35%;
}
.promo-detail {
  text-align: right;
}
.promo-plan-list {
  .promo-plan-list-header {
    color: #637381;
    font-size: 14px;
    font-weight: 400px;
  }
  .promo-plan-content {
    max-height: 370px;
    overflow: auto;
  }
}
</style>
