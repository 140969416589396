<template>
  <div class="promo-period">
    <v-row no-gutters>
      <div class="hb-inline-sentence-text">
        What month of the lease should the promotion start?*
      </div>
      <hb-text-field
        condensed 
        box
        x-small
        v-model="form.offset"
        placeholder="0"
        v-validate="'required|numeric|between:1,100'"
        data-vv-name="start_month"
        data-vv-as="Promotion Start Month"
        :error="errors.collect('start_month').length > 0"
        :disabled="consecutiveDisable"
        @change="setNextMonth()"
      />
    </v-row>

    <v-row no-gutters>
      <div class="hb-inline-sentence-text">
        Duration of Promotion (months)*
      </div>
      <hb-text-field
        condensed 
        box
        x-small
        v-model="form.duration"
        placeholder="0"
        v-validate="'required|numeric'"
        data-vv-name="duration"
        data-vv-as="Duration"
        :error="errors.collect('duration').length > 0"
      />
    </v-row>

    <hb-checkbox 
      v-model="form.consecutive_pay"
      description="<example of what this means>"
      :disabled="!enableConsecutiveMonth"
      @change="periodUpdate()"
    >
      <template v-slot:label>
        Apply this promotion to the next consecutive month (30 days).
      </template>
    </hb-checkbox>

    <hb-checkbox 
      v-model="form.isNextMonth" 
      :readonly="readOnlyStatus"
      class="mb-0"
      description="*This setting is not applicable to anniversary billing."
    >
      <template v-slot:content>
        <v-row no-gutters>
          <div class="hb-inline-sentence-text">
            Apply promotion to the next month after this day.
          </div>
          <HbSelect
            condensed
            :readonly="readOnlyStatus"
            box
            :disabled="!form.isNextMonth"
            medium
            attach
            @click.stop.prevent="setValue()"
            v-model="form.days_threshold"
            v-validate
            :items="getDays"
          />
        </v-row>
      </template>
    </hb-checkbox>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import HbSelect from '../../../aviary/HbSelect.vue';

export default {
  components: { HbSelect },
  name:"PromoPeriod",
  props: {
    period: {
      type: Object,
      default: () => {}
    },
    enableConsecutiveMonth: {
      type:Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultPeriod: {
        offset: null,
        duration: null,
        consecutive_pay: false,
        isNextMonth: false,
        days_threshold: 1   
      },
      form: {},
      readOnlyStatus: false,
      consecutiveDisable: false,
    }
  },
  watch: {
    period: {
      immediate: true,
      handler(data) {
        if(Object.keys(data)?.length) {
          this.$nextTick(() => {
            this.form = cloneDeep(data)
            if(this.form.days_threshold) this.form['isNextMonth'] = true
            // this.periodUpdate();
            if(this.form?.offset >= 0) {
              this.form.offset++;
              this.setNextMonth();
            }
          })
        }
        else this.form = cloneDeep(this.defaultPeriod)
      }
    },
    enableConsecutiveMonth: {
      handler(val) {
        if(!val) this.form.consecutive_pay = false;
        this.periodUpdate();
      },
      immediate: true,
    }
  },
  computed: {
    getDays() {
      let days = Array(31)
        .fill(0)
        .map((n, i) => i );
      return days;
    },
  },
  methods: {
    periodUpdate() {
      if(this.form.consecutive_pay) {
        this.consecutiveDisable = true;
        this.form.offset = 1;
        this.readOnlyStatus = true;
        this.form.days_threshold = 0
        this.form.isNextMonth = true 
      } else {
        this.consecutiveDisable = false;
        this.form.offset = null;
        this.readOnlyStatus = true;
        this.form.days_threshold = 0;
        this.form.isNextMonth = false;
      }
    },
    setNextMonth() {
      if(this.form.offset && this.form.offset > 1 ) {
        this.form.days_threshold = 1;
        this.form.isNextMonth = true;
        this.readOnlyStatus = true;
      } else {
        this.readOnlyStatus = false;
      }
    },
    setValue() {
      console.log("=-=-=-=-==-=--=-=-=-=-=-=-setValue=-=-==-=-=-=-=-=-=-=-=-");
    },
    getData() {
      return {
        duration : this.form.duration,
        offset: this.form.offset - 1,
        consecutive_pay: !!this.form.consecutive_pay,
        days_threshold: this.form.isNextMonth ? this.form.days_threshold : null
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>