<template>
  <v-dialog
    v-model="assignPromo"
    scrollable
    slot="body"
    width="1080"
    :content-class="'hb-modal-wrapper'"
    persistent
    @close="_cancel"
  >
    <v-card class="promo-modal">
      <v-card-title
        class="hb-primary-lighter hb-modal-title d-flex align-center ma-0 pa-0"
      >
        <v-col :cols="10" class="ma-0 py-0 px-4 d-flex align-center">
          <span class="hb-modal-title-font-size pa-3">{{ assignPromoTitle }}</span>
        </v-col>
        <v-col
          :cols="2"
          class="ma-0 pa-0 hb-modal-header-close-padding-right d-flex justify-end"
        >
          <hb-btn icon tooltip="Close" @click="_cancel" active-state-off>
            mdi-close
          </hb-btn>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-title>
        <span class="ma-0 pa-0 hb-text-light hb-font-caption"
          >Choose a promotion plan to be assigned</span
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-title class="pb-0">
        <v-row>
          <v-col class="py-0">
            <hb-radio-group row v-model="assignType">
              <HbRadio label="Single Promotion" value="0" />
              <HbRadio v-if="propertyPromotionSettings.automation_enabled_by_admin" label="Promotion Plan" value="1"/>
              <HbRadio label="No Promotion" value="2"/>
            </hb-radio-group>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="assignType === '0'">
        <hb-select :items="promotions" v-model="selectedPromotion">
          <template v-slot:item="{ item }">
            <MultilinePromoViewer :promotionId="item" />
          </template>
          <template v-slot:selection="{ item }">{{
            activePromotions.find((promo) => promo.id === item)?.name
          }}</template>
        </hb-select>
      </v-card-text>
      <v-card-text v-else-if="assignType === '1'">
        <div class="promotion-plans-field pt-1">
          <promo-plan-card
            v-for="(plan, index) in promoPlans"
            :key="index + plan.name"
            :type="'select'"
            :promo="plan"
            :isSelected="plan.id === selectedPlan"
            @click.native="selectPromoPlan(plan.id)"
          />
        </div>
      </v-card-text>
      <v-card-text v-else> </v-card-text>
      <hb-bottom-action-bar @close="_cancel()">
        <template v-slot:right-actions>
          <slot name="right-actions"></slot
          ><hb-btn :disabled="!!promoData?.unit_group_hashed_ids && !enableSave" 
            :loading="loading" @click="applyPromotion()">Save</hb-btn>
        </template>
      </hb-bottom-action-bar>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "../../../EventBus";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import api from "@/assets/api.js";
import PromoPlanCard from "./PromoPlanCard.vue";
import MultilinePromoViewer from "./MultilinePromoViewer.vue";

export default {
  name: "AssignPromoPlan",
  components: {
    PromoPlanCard,
    MultilinePromoViewer,
  },
  mixins: [notificationMixin],
  data() {
    return {
      assignPromo: false,
      selectedPlan: "",
      isSelected: false,
      assignType: "",
      selectedPromotion: "",
      promoData: {},
      loading: false,
      channel_type: "",
    };
  },

  computed: {
    ...mapGetters({
      promoPlans: "revManStore/promotionPlans",
      activePromotions: "revManStore/getActivePromotions",
      selectedProperty: "revManStore/getSelectedProperty",
      propertyPromotionSettings: "revManStore/getPropertyPromotionSettings",
    }),

    promotions() {
      return this.activePromotions
        .filter(
          (promo) =>
            promo.label === "promotion" &&
            !promo?.conditions?.includes("existing_tenants")
        )
        .map((promo) => promo.id);
    },

    assignPromoTitle() {
      return this.channel_type === "online" ? `Assign Online Only Promotions` : 
      `Assign In-Store & Online Promotions`
    },

    promoType() {
      let promo = {
        '0': "promotion",
        '1': "plan",
        '2': null,
      };
      return promo[this.assignType];
    },

    enableSave() {
      if (this.promoType === 'plan') return !!this.selectedPlan 
      if (this.promoType === 'promotion') return !!this.selectedPromotion
      if (this.promoType === null) return true
      return false;
    }
  },

  methods: {
    _cancel() {
      this.assignPromo = false;
      this.selectedPlan = "";
      this.selectedPromotion = "";
      this.$emit('promoClosed')
    },

    show(data, event) {
      this.assignPromo = true;
      this.assignType = '0';
      if (event === "assignPromoOnline" || event === "assignPromoInstore") {
        if (event === "assignPromoOnline") this.channel_type = "online";
        else this.channel_type = "instore_online";
        this.promoData.unit_group_hashed_ids = [data.spacegroup_id_hash];
      }
    },

    selectPromoPlan(id) {
      this.selectedPlan = id;
    },

    async applyPromotion() {
      this.promoData.promotions = [];
      this.promoData.property_promotion_management_settings_id = this.propertyPromotionSettings.id;
      let data = {
        data:
          this.selectedPlan || this.selectedPromotion
            ? {
                id: this.selectedPlan || this.selectedPromotion,
                type: this.promoType,
              }
            : null,
        channel_type: this.channel_type,
      };
      this.promoData.promotions.push(data);
      try {
        let url =
          api.getPropertyPromoManagementUrl(this.selectedProperty) +
          "space-groups/assign";
        this.loading = true;
        await api.post(this, url, this.promoData);
        this.showMessageNotification({
          type: "success",
          description: this.promoData?.promotions[0]?.data
            ? `You have successfully assigned a ${this.promoType}.`
            : `You have successfully removed a promotion/plan.`,
        });
        this._cancel();
        this.$emit("promoApplied");
      } catch (err) {
        this.showMessageNotification({
          type: "error",
          description: err,
        });
      } finally {
        this.loading = false;
      }
    },
  },

  watch: {
    assignType: {
      handler(newValue, oldValue) {
        if (oldValue !== '0') this.selectedPlan = "";
        if (oldValue !== '1') this.selectedPromotion = "";
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.promotion-plans-field {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;

  @media (max-width: 950px) {
    grid-template-columns: 1fr;
  }
}

.promo-modal {
  height: 680px;

  @media (max-height: 900px) {
    height: 630px;
  }
}
</style>
