<template>
  <div>
    <hb-form
      v-for="(override, index) in tierOverrides"
      :label="capitalizeFirstLetter(override.tier_type)"
      :key="index"
    >
      <hb-select
        v-model="override.type"
        placeholder="No Override Selected"
        :items="overrideTypes"
        item-value="value"
        item-text="label"
        :clearable="false"
        @input="clearPromotion(index)"
        v-validate="'required'"
        :data-vv-as="capitalizeFirstLetter(override.tier_type)"
        :data-vv-name="override.tier_type"
        :error-messages="errors.collect(override.tier_type)"
      />
      <hb-select
        v-if="override.type === 'promotion'"
        v-model="override.promotion_id"
        placeholder="No Promotion Selected"
        :items="activePromotions"
        item-value="id"
        item-text="name"
        :loading="promoLoading"
        :clearable="false"
        v-validate="'required'"
        data-vv-as="Promotion"
        data-vv-name="promotion_id"
        :error-messages="errors.collect(`promotion_id`)"
      />
    </hb-form>
  </div>
</template>
<script>
import { capitalizeFirstLetter } from "../../../utils/common";

import { mapGetters } from "vuex";

export default {
  name: "CreatePlanTierOverrides",
  components: {},
  mixins: [],
  props: {
    stepData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      promoLoading: false,
      overrideTypes: [
        {
          value: "none",
          label: "No Promotion",
        },
        {
          value: "scheduled",
          label: "Scheduled Promotion",
        },
        {
          value: "promotion",
          label: "Promotion Library",
        },
      ],
      tierOverrides: [
        {
          tier_type: "good",
          type: "",
          promotion_id: "",
        },
        {
          tier_type: "better",
          type: "",
          promotion_id: "",
        },
        {
          tier_type: "best",
          type: "",
          promotion_id: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getActivePromotions: "revManStore/getActivePromotions",
    }),
    activePromotions() {
      return this.getActivePromotions.filter(
        (promo) =>
          promo?.label === "promotion" &&
          !promo?.conditions?.includes("existing_tenants")
      );
    },
  },
  methods: {
    capitalizeFirstLetter(text) {
      return capitalizeFirstLetter(text);
    },
    clearPromotion(index) {
      if (this.tierOverrides[index].type != "promotion")
        this.tierOverrides[index].promotion_id = "";
    },
    async submit() {
      const valid = await this.$validator.validateAll();
      let data = this.tierOverrides.filter((tier) => tier && tier.type);
      return valid
        ? {
            status: true,
            validatedData: {
              value_tier_overrides: data.length ? data : null,
            },
          }
        : {
            status: false,
            validatedData: {},
          };
    },
    resetData() {
      this.$validator.reset();
      this.tierOverrides.forEach((override) => {
        override.type = "";
        override.promotion_id = "";
      });
    },
  },

  watch: {
    stepData: {
      handler(value) {
        this.tierOverrides = value.value_tier_overrides ?? this.tierOverrides;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped></style>
