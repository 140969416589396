<template>
  <div class="multi-line">
    <span>{{ effectCount }} {{ effect.type  }}</span>
    <span :class="{ dashed : effectCount.length }" :style="{ color: '#8E8E8F'}" >{{ getLine }}</span>
  </div>
</template>
<script>
import { capitalizeFirstLetter } from "@/utils/common";
export default {
  name: "PromotionsEffectRenderer",
  data() {
    return {
      effect: {},
      effectCount: ''
    }
  },
  computed: {
    getLine() {
      let line = ''
      if(this.effect?.item) line += this.effect?.item + ':' 
      if(this.effect?.value_type === 'dollar') line += ` $${this.effect.value} Off`
      else if(this.effect?.value_type === 'fixed') line += ` Fixed Rate: $${this.effect.value}`
      else if(this.effect?.value_type === 'percent') line += ` ${this.effect.value}% Off`;
      return line
    }
  },
  created() {
    if(this.params?.data.promotion_effects && this.params?.data?.promotion_effects.length > 0) {
      let index = this.params?.data?.promotion_effects.findIndex(effect => effect.on === 'rent') !== -1
        ? this.params?.data?.promotion_effects.findIndex(effect => effect.on === 'rent')
        : 0
      this.effect = {
        type: capitalizeFirstLetter(this.params?.data?.promotion_effects[index].on) || '',
        item: this.params?.data?.promotion_effects?.[index]?.product_name || '',
        value: this.params?.data?.promotion_effects?.[index]?.amount?.value || '',
        value_type: this.params?.data?.promotion_effects?.[index]?.amount?.type || ''
      }
      this.effectCount = this.params?.data.promotion_effects.length > 1 
        ? `(${this.params?.data.promotion_effects.length})`
        : ``
    }
  }
}
</script>
<style lang="scss" scoped>
.multi-line {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    &.dashed {
      margin-top: 0;
      border-top: 1px dashed #637381;
    }
  }
}
</style>