<template>
  <div style="width: 350px">
    <template v-if="effect">
      <p class="hb-text-light">Effect {{ index + 1 }}</p>
      <HbSelect
        v-model="effect.on"
        :items="effectTypes"
        item-value="item"
        item-text="label"
        v-validate
        :data-vv-name="`effect_${index}`"
        attach
        placeholder="Select Effect"
        :clearable="false"
        :error="effect.errors.on"
      />
      <hb-combobox
        class="mt-3"
        select
        v-if="effect.on === 'merchandise'"
        v-model="effect.merchandise_items"
        :items="products"
        item-value="id"
        item-text="name"
        v-validate
        attach
        :data-vv-name="`merchandise_item_${index}`"
        :error="effect.errors.merchandise_items"
        :clearable="false"
        label="Select Item"
    ></hb-combobox>
      <v-row no-gutters class="mt-5">
        <HbSelect
          box
          condensed
          medium
          v-model="effect.amount.type"
          :items="amountTypes"
          item-value="item"
          item-text="label"
          v-validate
          :data-vv-name="`method_${index}`"
          attach
          placeholder="$"
          :clearable="false"
          :error="effect.errors.type"
        />
        <hb-text-field
          box
          condensed
          medium
          v-model="effect.amount.value"
          placeholder="Amount"
          v-validate
          :data-vv-name="`amount_${index}`"
          :error="effect.errors.value"
        />
      </v-row>
    </template>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "PromoEffect",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
    showRent: {
      type: Boolean,
      default: true,
    },
    products: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      effect: null,
      defaultEffect: {
        on: null,
        merchandise_items: [],
        amount: {
          type: "",
          value: null,
        },
        errors: {
          on: false,
          merchandise_items: false,
          type: false,
          value: false,
        },
      },
      effectList: [],
      amountTypes: [
        {
          item: "dollar",
          label: "$",
        },
        {
          item: "percent",
          label: "%",
        },
        {
          item: "fixed",
          label: "Fixed Rate",
        },
      ],
    };
  },
  watch: {
  },
  mounted() {
    this.setData();
  },
  computed: {
    effectTypes() {
      return [
        ...(this.showRent || this.effect.on == "rent"
          ? [
              {
                item: "rent",
                label: "Rent",
              },
            ]
          : []),
        {
          item: "merchandise",
          label: "Merchandise",
        },
      ];
    },
  },
  methods: {
    showError(value) {
      value.forEach((item) => {
        this.effectList[item.index]["errors"] = item;
      });
    },
    setData() {
      this.effect = this.item
      this.effect['errors'] = {
          on: false,
          merchandise_items: false,
          type: false,
          value: false,
        }
      if (this.item.product_id) {
        this.effect.merchandise_items = {
          id:this.item.product_id,
          name:this.item.product_name,
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>

