<script type="text/babel">
import { EventBus } from "../../../EventBus.js";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "PromotionsLibraryActions",
  props: {
    promoId: {
      type: String,
      default: "",
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    items() {
      return [
        {
          text: "Edit",
          event: "editPromo",
        },
        {
          text: "Delete",
          event: "deletePromo"
        },
        {
          text: "Duplicate",
          event: "duplicatePromo"
        },
        // {
        //   text: "Set as Default",
        //   event: "setAsDefaultPromo"
        // },
      ];
    },
  },
  methods: {
    triggerEvent(event) {
      EventBus.$emit("promoLibraryEvents", {
        event,
        data: this.params.data,
      });
    },
  },
};
</script>

<template>
  <div>
    <!-- Populate Menu items -->
    <v-card class="mx-auto" max-width="500">
      <v-list>
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in items"
            @click="triggerEvent(item.event)"
            :key="i"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    <!-- Populate Menu items -->
  </div>
</template>
