<template>
  <hb-modal
    v-model="dialog"
    size="large"
    height="small"
    title="View Discount"
    footer-off
    @close="$emit('close')"
  >
    <template slot="content">
      <hb-form
        v-for="(item, index1) in discountViewOrder"
        :key="index1"
        :editable="false"
        :label="updateTitleName(item)"
      >
        <span v-if="Array.isArray(discountData[item])">
          <li v-for="(list, index2) in discountData[item]" :key="index2">
            <span v-if="item === 'effects'">
              <span>
                {{ formatEffect(list) }}
              </span>
            </span>
            <span v-else> {{ list | capitalize }}</span>
          </li>
        </span>

        <span v-else-if="item === 'assigned_on'">
          {{ discountData[item] | formatDate("MMM DD, YYYY [@] h:mma") }}
        </span>
        <span v-else-if="item === 'assigned_by'">
          {{ discountData[item] | capitalize }}
        </span>
        <span v-else>{{ discountData[item] }}</span>
      </hb-form>
    </template>
  </hb-modal>
</template>

<script>
import { capitalizeFirstLetter } from "../../../utils/common";

export default {
  name: "ViewDiscountModal",
  components: {},
  data() {
    return {
      dialog: false,
      discountData: {},
      discountViewOrder: [
        "name",
        "description",
        "effects",
        "eligible_tenants",
        "assigned_by",
        "assigned_on",
      ],
    };
  },

  computed: {},

  methods: {
    show(itemData) {
      this.discountData = {};
      this.dialog = true;
      let { id, ...newData } = itemData;
      this.discountData = newData;
    },
    updateTitleName(text) {
      return capitalizeFirstLetter(text.replace(/_/g, " "));
    },
    formatEffect(effectItem) {
      const { on, amount } = effectItem;
      switch (amount.type) {
        case "fixed":
          return `${capitalizeFirstLetter(on)}${
            on === "merchandise" ? ` - ${effectItem.product_name}` : ""
          } - Fixed Rate: $${amount.value} Off`;
        case "dollar":
          return `${capitalizeFirstLetter(on)}${
            on === "merchandise" ? ` - ${effectItem.product_name}` : ""
          } - $${amount.value} Off`;
        case "percent":
          return `${capitalizeFirstLetter(on)}${
            on === "merchandise" ? ` - ${effectItem.product_name}` : ""
          } - ${amount.value}% Off`;
        default:
          return "";
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
