<template>
  <hb-modal
    size="medium"
    :title="`Duplicate ${capitalizeLetter(promoData.promotion_type)}?`"
    v-model="dialog"
    confirmation
    @close="close()"
  >
    <template v-slot:content>
      <div class="content py-4 px-6 d-flex flex-column">
        <div v-html="popupDescription"></div>
      </div>
    </template>
    <template v-slot:right-actions>
      <hb-btn :loading="loading" @click="duplicatePromo">Continue</hb-btn>
    </template>
  </hb-modal>
</template>
<script>
import api from "../../../assets/api.js";

import { notificationMixin } from "@/mixins/notificationMixin.js";
import { capitalizeFirstLetter } from "../../../utils/common";

export default {
  name: "DuplicatePromoPopUp",
  components: {},
  mixins: [notificationMixin],
  props: {},
  data() {
    return {
      dialog: false,
      promoData: {},
      popupDescription: "",
      loading: false,
    };
  },
  computed: {
    getApprovalConfirmationMessage() {
      return `You are about to  <span class="font-weight-medium">duplicate</span> the ${this.promoData.promotion_type} <span class="font-weight-medium">${this.promoData.promotion_name}</span>?`;
    },
  },
  methods: {
    close() {
      this.$emit("close", this.promoData?.promotion_id ?? "");
    },
    show(data) {
      this.dialog = true;
      this.promoData = data.rowData;
      this.popupDescription = this.getApprovalConfirmationMessage;
    },
    capitalizeLetter(text) {
      return capitalizeFirstLetter(text);
    },
    removeProductDetails(effects) {
      return effects?.map((effect) => {
        const { on } = effect;
        if (on === "rent" || on === "merchandise") {
          delete effect["product_name"];
        }
        if (on === "rent") {
          delete effect["product_id"];
        }
        return effect;
      });
    },

    async duplicatePromo() {
      this.loading = true;
      try {
        let {
          promotion_type: label,
          promotion_name: name,
          promotion_description: description,
          promotion_length_of_stay: length_of_stay,
          promotion_required_months: required_months,
          promotion_effects: effects,
          promotion_conditions: conditions,
          promotion_promo_code: promo_code,
          promotion_eligible_tenants: eligible_tenants,
          promotion_period: period,
        } = this.promoData;

        let data = {
          label,
          name: `Copy of ${name}`,
          description,
          effects: this.removeProductDetails(effects),
          promo_code: promo_code ?? {},
          ...(label === "promotion" && {
            length_of_stay,
            required_months,
            period,
            conditions,
          }),
          ...(label === "discount" && {
            eligible_tenants,
          }),
        };
        await api
          .post(this, api.PROMOTIONS, data)
          .then(() => {
            this.showMessageNotification({
              type: "success",
              description: `${this.capitalizeLetter(
                this.promoData.promotion_type
              )} duplicated successfully`,
            });
            this.dialog = false;
          })
          .catch((error) => {
            console.log(error);
            this.showMessageNotification({
              type: "error",
              description: `${this.capitalizeLetter(
                this.promoData.promotion_type
              )} duplication failed.`,
            });
          });
        this.$emit("duplicated", true);
      } catch (error) {
        console.log(error, "Promo duplication API failed");
        this.$emit("duplicated", false);
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  gap: 16px;
}
</style>
