<template>
  <div class="promo-effect mt-1">
    <div
      v-for="(item, index) in effectList"
      :key="index"
      class="d-flex justify-space-between align-center"
      :class="{ 'mt-2': index !== 0 }"
    >
      <div style="width: 350px">
        <p class="hb-text-light">Effect {{ index + 1 }}</p>
        <HbSelect
          v-model="item.on"
          :items="getFilteredEffectTypes(index)"
          item-value="item"
          item-text="label"
          v-validate
          :data-vv-name="`effect_${index}`"
          attach
          placeholder="Select Effect"
          :clearable="false"
          :error="item.errors.on"
          @change="effectUpdateEmits()"
        />
        <hb-combobox
          class="mt-3"
          select
          v-if="item.on === 'merchandise'"
          v-model="item.merchandise_items"
          :items="getProducts(index)"
          item-value="id"
          item-text="name"
          v-validate
          return-object
          attach
          :data-vv-name="`merchandise_item_${index}`"
          :error="item.errors.merchandise_items"
          :clearable="false"
          label="Select Item"
        ></hb-combobox>
        <v-row no-gutters class="mt-5">
          <HbSelect
            box
            condensed
            medium
            v-model="item.amount.type"
            :items="amountTypes"
            item-value="item"
            item-text="label"
            v-validate
            :data-vv-name="`method_${index}`"
            attach
            placeholder="$"
            :clearable="false"
            :error="item.errors.type"
            @change="effectUpdateEmits()"
          />
          <hb-text-field
            box
            condensed
            medium
            v-model="item.amount.value"
            placeholder="Amount"
            v-validate
            :data-vv-name="`amount_${index}`"
            :error="item.errors.value"
          />
        </v-row>
      </div>
      <!-- <PromoEffect
        :item="item"
        :index="index"
        :key="`${index}-${item.merchandise_items ? item.merchandise_items.length : 'item'}`"
        :show-rent="showRent"
        :products="allProducts"
        ref="promoEffect"
      /> -->

      <div v-if="index !== 0">
        <hb-link @click="removeEffect(index)">Remove</hb-link>
      </div>
    </div>
    <hb-link v-if="showAddEffects" @click="addEffects">+ Add Effect</hb-link>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import PromoEffect from "./PromoEffect.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "PromoEffectList",
  components: {
    PromoEffect,
  },
  props: {
    effects: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      defaultEffect: {
        on: null,
        merchandise_items: [],
        amount: {
          type: 'dollar',
          value: null,
        },
        errors: {
          on: false,
          merchandise_items: false,
          type: false,
          value: false,
        },
      },
      effectList: [],
      effectTypes: [
        {
          item: "rent",
          label: "Rent",
        },
        {
          item: "merchandise",
          label: "Merchandise",
        },
      ],
      amountTypes: [
        {
          item: "dollar",
          label: "$",
        },
        {
          item: "percent",
          label: "%",
        },
        {
          item: "fixed",
          label: "Fixed Rate",
        },
      ],
    };
  },
  watch: {
    effects: {
      immediate: true,
      handler(list) {
        if (list?.length) {
          this.$nextTick(() => {
            this.effectList = this.mapEffects(cloneDeep(list));
            this.effectUpdateEmits();
          });
        } else {
          this.effectList = [cloneDeep(this.defaultEffect)];
          this.effectUpdateEmits();
        }
      },
    },
  },
  async mounted() {
    // if (!this.allProducts.length) await this.setProducts();
  },
  computed: {
    ...mapState({
      allProducts: (state) => state.revManStore.promotion.products ?? [],
    }),
    showRent() {
      return (
        !this.effectList.length ||
        !this.effectList.find((item) => item.on == "rent")
      );
    },
    selectedProducts() {
      let effects = [];
      this.effectList.forEach((effect) => {
        if (effect.on != "rent") {
          effects = [...effects, ...effect.merchandise_items];
        }
      });
      return effects;
    },
    allProductsSelected() {
      return this.allProducts.length == this.selectedProducts.length;
    },
    allProductsSelectedInOneEffect() {
      return (
        this.effectList.filter((list) => list.on == "merchandise").length ==
          1 && this.allProductsSelected
      );
    },
    showAddEffects() {
      return !(this.allProductsSelected && !this.showRent);
    },
  },
  methods: {
    ...mapActions({
      fetchProducts: "revManStore/fetchProducts",
    }),
    
    effectUpdateEmits() {
      if (
          this.effectList.filter(
            (item) => item.on === "rent" && item?.amount?.type === "fixed"
          ).length
        ) {
          this.$emit("consecutive-month-view", true);
        } else this.$emit("consecutive-month-view", false);
    },

    getProduct(id) {
      return this.allProducts.find((product) => product.id == id);
    },
    mapEffects(list) {
      let effects = [];

      let obj = {};
      list.forEach((effect) => {
        effect["errors"] = {
          on: false,
          merchandise_items: false,
          type: false,
          value: false,
        };
        if (effect.on == "merchandise") {
          if (effect.product_id) {
            let key = effect.amount.type + effect.amount.value;
            if (Object.keys(obj).includes(key)) {
              obj[key].merchandise_items.push(
                this.getProduct(effect.product_id)
              );
            } else
              obj[key] = {
                ...effect,
                merchandise_items: [this.getProduct(effect.product_id)],
              };
          } else {
            effects.push({
              ...effect,
              merchandise_items: this.allProducts,
            });
          }
        } else {
          effects.push({
            ...effect,
            merchandise_items: [],
          });
        }
      });
      effects = [...effects, ...Object.values(obj)];

      return effects;
    },
    getSelectedProductsInOtherEffects(currentIndex) {
      let items = [];
      this.effectList.forEach((effect, index) => {
        if (index != currentIndex && effect.on != "rent")
          items = [
            ...items,
            ...(effect.merchandise_items ? effect.merchandise_items : []),
          ];
      });
      return items;
    },
    getFilteredEffectTypes(index) {
      let types = [];
      if (this.showRent || this.effectList[index].on == "rent")
        types.push({
          item: "rent",
          label: "Rent",
        });
      if (
        !this.allProductsSelected ||
        this.effectList[index].on == "merchandise"
      )
        types.push({
          item: "merchandise",
          label: "Merchandise",
        });
      return types;
    },
    getProducts(index) {
      let filteredProducts = this.getSelectedProductsInOtherEffects(index);
      return this.allProducts.filter(
        (product) => !filteredProducts.some((item) => item.id == product.id)
      );
    },
    async addEffects() {
      this.effectList.push(cloneDeep(this.defaultEffect));
      if (!this.showRent) {
        this.effectTypes = [
          {
            item: "merchandise",
            label: "Merchandise",
          },
        ];
      }
    },
    async removeEffect(index) {
      let removed = this.effectList.splice(index, 1)[0];
      this.effectUpdateEmits();
    },
    showError(value) {
      value.forEach((item) => {
        this.effectList[item.index]["errors"] = item;
      });
    },
    getData() {
      let result = [];
      this.effectList.forEach((effect) => {
        let { on, amount, merchandise_items } = effect;
        let obj = { on, amount };
        if (on == "merchandise") {
          if (this.allProductsSelectedInOneEffect) {
            obj["product_id"] = null;
            result.push(cloneDeep(obj))
          } else
            merchandise_items.forEach((item) => {
              obj["product_id"] = item.id;
              result.push(cloneDeep(obj));
            });
        } else result.push(obj);
      });
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>

