<template>
  <PlanCardLayout
    :tabs="tabsData"
    :apiLoading="apiLoading"
    :isSelected="isSelected"
    :tabHeight="450"
  >
    <template slot="title-icons">
      <template v-if="showDescriptiveIcons">
        <DescriptiveTags
          :plan="descriptiveTagProperties"
          :conf="{ class: 'mr-3' }"
        />
      </template>
    </template>
    <template slot="title">
      {{ promo.name }}
    </template>
    <template v-if="isDefault" slot="default-icon">
      <hb-icon color="#00848E"> mdi-flag </hb-icon>
    </template>
    <template v-if="promo.last_modified" slot="update-info">
      {{ promo.last_modified }}
    </template>
    <template v-if="isManageable" slot="option">
      <hb-menu options attach nudge-left="68">
        <v-list>
          <v-list-item
            v-for="(action, i) in cardActions"
            :key="i"
            :ripple="false"
            @click="showPopup(action.key)"
          >
            <v-list-item-title>{{ action.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </hb-menu>
    </template>
    <template v-else-if="isClosable" slot="option">
      <hb-btn @click="$emit('close')" active-state-off icon tooltip="Close">
        mdi-close
      </hb-btn>
    </template>
    <template slot="description">
      {{ promo.description }}
    </template>
    <template v-for="tab in tabsData">
      <template :slot="tab.key">
        <component
          :is="tab.componentName"
          v-bind="tab.componentProps"
          :key="tab.key"
        />
      </template>
    </template>
    <template slot="form">
      <CreatePromotionPlan @success="fetchPromotionPlans" ref="editPromoPlan"
    /></template>
    <template slot="confirmation">
      <!-- Confirmation Modal for delete promo plan and set as default -->
      <ConfirmationPopup v-once ref="confirmationPopup" />
    </template>
  </PlanCardLayout>
</template>
<script>
import PlanCardLayout from "../utils/PlanCardLayout.vue";
import ConfirmationPopup from "../utils/ConfirmationPopup.vue";
import DescriptiveTags from "../utils/DescriptiveTags.vue";
import PromotionPlanOverrides from "./PromotionPlanOverrides.vue";
import PromotionPlanValueTiers from "./PromotionPlanValueTiers.vue";
import PromoPlanDefault from "./PromoPlanDefault.vue";
import CreatePromotionPlan from "./CreatePromotionPlan.vue";

import { mapActions, mapMutations, mapGetters } from "vuex";
import api from "../../../assets/api.js";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { cloneDeep } from "lodash";

export default {
  name: "PromoPlanCard",
  mixins: [notificationMixin],
  props: {
    promo: {
      type: Object,
      required: true,
      default: () => {},
    },
    type: {
      type: String,
      default: "manage",
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    editPermission: {
      type: Boolean,
      default: true,
    },
    showDescriptiveIcons: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      defaultPromotionPlan: "revManStore/defaultPromotionPlan",
      companyPromotionSettings: "revManStore/companyPromotionSettings",
    }),
    cardActions() {
      let actions = [
        {
          name: "Edit",
          key: "edit",
        },
      ];

      if (!this.isDefault) {
        actions.push(
          {
            name: "Delete",
            key: "delete",
          }
          // {
          //   name: "Set as Default",
          //   key: "default",
          // }
        );
      }

      actions.push({
        name: "Duplicate",
        key: "duplicate",
      });
      return actions;
    },
    confirmationContent() {
      return {
        delete: {
          title: "Delete Promo Plan",
          description: `You are about to delete <strong>${this.promo.name}</strong> promo plan.<br/><br/>Are you sure you want to continue?`,
          alt_description: `The promotion plan <strong>${this.promo.name}</strong> you're trying to delete is currently assigned to one or more space groups.<br/><br/>To delete this promotion plan, first remove it from the space groups of following properties.`,
        },
        default: {
          title: "Set Default Promo Plan",
          description:
            " Be advised that if you select a new default promo plan, then any groups currently using your default plan will change over to your new selection.",
        },
        duplicate: {
          title: "Duplicate Promo Plan",
          description: `You are about to duplicate <strong>${this.promo.name}</strong> promo plan.<br/><br/>Are you sure you want to continue?`,
        },
      };
    },
    isManageable() {
      return this.type == "manage" && this.editPermission;
    },
    isClosable() {
      return this.type == "close";
    },
    showDefaultIconOnRight() {
      return this.type == "select";
    },
    isDefault() {
      return this.promo?.isDefault ?? false;
    },
    descriptiveTagProperties() {
      return {
        tags: this.promo?.tags ?? [],
        price_delta_type: this.promo?.price_delta_type ?? "",
      };
    },
    tabsData() {
      //TODO
      return [
        {
          label: "Plan Default",
          key: "plan-default",
          componentName: "PromoPlanDefault",
          componentProps: { promo: this.promo },
        },
        {
          label: "Overrides",
          key: "plan-overrides",
          componentName: "PromotionPlanOverrides",
          componentProps: { promo: this.promo },
          badgeValue: this.promo?.overrides?.length,
        },
        // {
        //   label: "Value Tiers",
        //   key: "value-tiers",
        //   componentName: "PromotionPlanValueTiers",
        //   componentProps: { promo: this.promo },
        //   tooltip: "Value Tiers",
        // },
      ];
    },
  },
  components: {
    PlanCardLayout,
    ConfirmationPopup,
    DescriptiveTags,
    PromotionPlanOverrides,
    PromotionPlanValueTiers,
    PromoPlanDefault,
    CreatePromotionPlan,
  },
  data() {
    return {
      activeTab: null,
      apiLoading: false,
      confirmationType: "delete",
      loading: false,
      promo_plan_usage: []
    };
  },
  methods: {
    ...mapMutations({
      setPromotionPlans: "revManStore/SET_PROMOTION_PLANS",
      updateCompanyPromotionSettings:
        "revManStore/SET_COMPANY_PROMOTION_SETTINGS",
    }),
    ...mapActions({
      fetchPromotionPlans: "revManStore/fetchPromotionPlans",
    }),
    /**
     * Function to display the confirmation modal.
     *
     * @param {String} type Type of the confirmation modal ( default/delete)
     */
    async showPopup(type = "default") {
      if (type === "edit") this.$refs.editPromoPlan.show(cloneDeep(this.promo));
      else {
        this.confirmationType = type;
        let useAlternate = false;
        if(type == 'delete'){
          await this.promoPlanUsage();
          if(this.promo_plan_usage.length){
            useAlternate = true;
          }
        }

        let confirmed = await this.$refs.confirmationPopup
          .show({
            title: this.confirmationContent[type].title,
            message: useAlternate ? this.confirmationContent[type].alt_description : this.confirmationContent[type].description,
            list: this.promo_plan_usage,
            buttonType: type == "delete" ? "destructive" : "primary",
            resolver: useAlternate ? '' : `Continue`,
          })
          .catch(() => false);
        this.promo_plan_usage = [];
        if (confirmed) await this.triggerPostConfirmationAction();
      }
    },

    /**
     * Function to trigger all the corresponding action after user confirms the delete,set as default, duplicate actions
     */
    async triggerPostConfirmationAction() {
      switch (this.confirmationType) {
        case "duplicate":
          await this.duplicatePlan();
          break;
        case "delete":
          await this.deletePromoPlan();
          break;
        case "default":
          await this.setPromoPlanToDefault();
          break;
      }
    },
    async duplicatePlan() {
      let { name, isDefault, id, ...promoData } = this.promo;
      let data = {
        name: `Copy of ${name}`,
        ...promoData,
      };
      await api
        .post(this, api.PROMOTIONS + "plans", data)
        .then(() => {
          this.showMessageNotification({
            type: "success",
            description: `Promotion plan '${name}' duplicated successfully`,
          });
          this.fetchPromotionPlans();
          this.dialog = false;
        })
        .catch((error) => {
          console.log(error);
          this.showMessageNotification({
            type: "error",
            description: error,
          });
        });
    },
    async promoPlanUsage() {
      await api
        .get(this, api.PROMOTIONS + "plans/" + this.promo.id + "/usage")
        .then(r => {
          console.log("Response:", r);
          if(r.assignedProperties && r.assignedProperties.length){
            this.promo_plan_usage = r.assignedProperties.map(t => { 
              return `${t.property_number} - ${t.property_name}` 
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async deletePromoPlan() {
      await api
        .delete(this, api.PROMOTIONS + "plans/" + this.promo.id)
        .then(() => {
          this.showMessageNotification({
            type: "success",
            description: `Promotion plan '${this.promo?.name}' deleted successfully`,
          });
          this.fetchPromotionPlans();
          this.dialog = false;
        })
        .catch((error) => {
          console.log(error);
          this.showMessageNotification({
            type: "error",
            description: `${error ? error : "Unable to delete Promotion plan."}`,
          });
        });
    },
    async setPromoPlanToDefault() {
      let promoSettings = cloneDeep(this.companyPromotionSettings);
      promoSettings.promotion_management_plan_id = this.promo.id;
      await api
        .put(this, api.PROMOTION_MANAGEMENT, promoSettings)
        .then(() => {
          this.showMessageNotification({
            type: "success",
            description: `Promotion Plan '${this.promo.name}' has been set as the company default successfully.`,
          });

          this.updateCompanyPromotionSettings(promoSettings);
          this.setPromotionPlans();
          this.dialog = false;
        })
        .catch((error) => {
          console.log(error);
          this.showMessageNotification({
            type: "error",
            description: `Unable to set default Promotion plan.`,
          });
          this.dialog = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
