<template>
  <div v-if="showToolTip" class="promo-tooltip pa-4">
    <v-row class="px-3">
      <hb-icon
        mdi-code="mdi-bullhorn"
        small
        color="#8DE3E3"
      />
      <span class="hb-font-header-3-medium pl-2" style="color: #8DE3E3; text-transform: capitalize;">{{ rendererParams.promotion_type }}</span>
    </v-row>
    <div v-if="params.showPromoDetails">
      <div class="hb-font-body-medium">{{ rendererParams.name }}</div>
      <div class="divider"></div>
      <span class="tooltip-description mt-1">{{ rendererParams.description }}</span>
    </div>
    <div v-if="params.column.colId === 'promotion_amount'">
      <span class="hb-font-body-medium ">Amount</span>
    </div>
    <div v-if="params.column.colId === 'promotion_start'">
      <span class="hb-font-body-medium ">Period</span>
      <ul class="list">
        <li v-for="(periodItem, index) in promotionPeriodItems" :key="index">
          <div class="d-flex">
            <span class="dot mr-2">&#8226;</span>
            <span>{{ periodItem }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="params.column.colId === 'promotion_conditions'">
      <span class="hb-font-body-medium ">Conditions</span>
      <ul class="list">
        <li v-for="(condition, index) in listConditions" :key="index">
          <div class="d-flex">
            <span class="dot mr-2">&#8226;</span>
            <span>{{ condition }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { capitalizeFirstLetter } from "../../../utils/common";
import { cloneDeep } from "lodash";
export default {
  name: "PromotionTooltip",
  // props: {
  //   promotion_name: {
  //     type: Boolean,
  //     default: false
  //   },
  //   promotion_effects: {
  //     type: Boolean,
  //     default: false
  //   },
  // },
  data() {
    return {}
  },
  computed: {
    effectList() {
      let effects = cloneDeep(this.params?.data?.promotion_effects ?? [])
      const indexToMoveToFront = effects.findIndex(effect => effect.on === 'rent');
      
      if (indexToMoveToFront !== -1) {
        const movedObject = effects.splice(indexToMoveToFront, 1)[0];
        effects.unshift(movedObject);
      }
      return effects
    },
    filteredEffectsList() {
      return cloneDeep(this.effectList).splice(0,10)
    },
    additionalEffectToDisplay() {
      return this.effectList.length > 10 ? this.effectList.length - 10 : 0
    },
    promotionPeriodItems() {
      let period = this.params.data.promotion_period
      const items = [
        `Apply from Month ${period.offset + 1}`,
        ...(period.duration > 1 ? [`For ${period.duration} Months`]: [`For ${period.duration} Month`]),
        ...(period.consecutive_pay ? ['Apply promo to next consecutive month'] : []),
        ...(period.days_threshold > 0 ? [`Apply promo to next month after Day ${period.days_threshold}`] : [])
      ]
      return items
    },
    listConditions() {
      const conditions = {
        new_tenants: 'New Tenants Only',
        existing_tenants: 'Existing Tenants Only',
        web_only: 'Web Only',
        require_autopay: 'Require Autopay',
        require_coverage: 'Require Protection Plan Enrollment',
        require_id_verification: 'Require ID Verification',
        remove_on_delinquency: 'Remove on Delinquency'
      }
      return this.params.data.promotion_conditions.map(condition => conditions[condition])
    },
    showToolTip() {
      return this.params.showToolTip(this.params.value)
    },
    rendererParams() {
      return this.params.rendererParams(this.params.data)
    }
  },
  methods: {
    formatEffect(effectItem) {
      const { on, amount, product_id, product_name } = effectItem;
      switch (amount.type) {
        case 'fixed':
          return `${capitalizeFirstLetter(on)}${on === 'merchandise' && product_id ? ` - ${product_name}` : ''} - Fixed Rate: $${amount.value}`;
        case 'dollar':
          return `${capitalizeFirstLetter(on)}${on === 'merchandise' && product_id ? ` - ${product_name}` : ''} - $${amount.value} Off`;
        case 'percent':
          return `${capitalizeFirstLetter(on)}${on === 'merchandise' && product_id ? ` - ${product_name}` : ''} - ${amount.value}% Off`;
        default:
          return '';
      }
    },
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.promo-tooltip {
  background-color: #334450;
  color: white; 
  box-shadow: 0px 8px 18px rgba(71, 84, 116, 0.2);
  max-width: 300px;
  // min-height: 424px;
  border-radius: 5px;
  .divider {
    width: 100%;
    border-top: 2px dashed #ffffff;
    padding-bottom: 4px;
  }
  .list {
    list-style: none;
  }
}

</style>