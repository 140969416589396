<template>
  <div>
    <hb-modal
      v-model="assignDiscount"
      title="Assign Discounts"
      @close="clearModal"
    >
      <template v-slot:subheader>
        Assign discounts to the property.
      </template>
      <template v-slot:content>
        <hb-form label="Discounts" :description="formDescription" full>
          <hb-combobox
            label="Select Discount"
            v-model="selectedDiscount"
            :items="discounts"
            :error="!valid"
            :key="comboKey"
            select
            return-object
          >
            <template v-slot:item="{ item, attrs }">
              <v-list-item-action>
                <v-icon v-if="isSelected(item)">check_box</v-icon>
                <v-icon v-else>check_box_outline_blank</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <MultilinePromoViewer :promotionId="item.id" />
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </hb-combobox>
        </hb-form>
      </template>
      <template v-slot:right-actions>
        <hb-btn :loading="loading" @click="assign">Save</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import api from "../../../assets/api";
import MultilinePromoViewer from "./MultilinePromoViewer.vue";
import { notificationMixin } from "@/mixins/notificationMixin.js";

export default {
  name: "AssignDiscount",
  mixins: [notificationMixin],
  components: {
    MultilinePromoViewer,
  },
  props: {
    propertyDiscounts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedDiscount: this.propertyDiscounts,
      assignDiscount: false,
      loading: false,
      valid: true,
      comboKey: false
    };
  },
  computed: {
    ...mapGetters({
      activePromotions: "revManStore/getActivePromotions",
      selectedProperty: "revManStore/getSelectedProperty",
    }),
    discounts() {
      return this.activePromotions
        .filter((promo) => promo.label === "discount")
        .map((promo) => ({ id: promo.id, name: promo.name }));
    },
    formDescription() {
      return `Select all of the discounts you would like to apply to the property.`;
    },
  },
  methods: {
    ...mapActions({
      fetchPromotions: "revManStore/fetchActivePromotions",
    }),

    show() {
      this.fetchPromotions();
      this.assignDiscount = true;
    },

    getPromoId(name) {
      return this.activePromotions.find((promo) => promo.name === name)[0]?.id;
    },
    clearModal() {
      this.assignDiscount = false
      this.selectedDiscount = this.propertyDiscounts;
    },
    isSelected(item) {
      return this.selectedDiscount.some((element) => element.id === item.id);
    },

    async assign() {
      this.valid = !(this.selectedDiscount.length === 0);
      if (!this.valid) {
        this.showMessageNotification({
          type: "error",
          description:
            "Please select atleast one discount to assign to the property.",
        });
        return;
      }
      try {
        this.loading = true;
        let url = api.getPropertyDiscountManagementUrl(this.selectedProperty);
        let data = {
          discount_ids:
            this.selectedDiscount.map((discount) => discount.id) || [],
        };
        await api.put(this, url, data);
        this.assignDiscount = false;
        this.showMessageNotification({
          type: "success",
          description: `You have successfully assigned discounts to the property.`,
        });
        this.$emit("discountAssigned");
      } catch (err) {
        this.showMessageNotification({
          type: "error",
          description: err,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    propertyDiscounts: {
      handler(value) {
        this.selectedDiscount = value;
      },
      immediate: true,
    },
    selectedDiscount: {
      handler(newval, oldval) {
        if (oldval?.length > 0 && newval?.length === 0) this.comboKey = !this.comboKey
      },
      immediate: true
    },
    assignDiscount: {
      handler(value) {
        if (value) this.valid = true
      }
    }
  },
};
</script>
