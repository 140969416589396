<template>
  <div>
    <RightSideNavigation
      v-model="selectedSideTab"
      :menu-items="rightSideMenuItems"
      :add-local-property-selector-height="properties.length > 1"
      :showTertiary="showTertiary"
    >
      <template v-slot:component>
        <PromotionAndDiscount
          v-if="selectedSideTab == 'promotions'"
          :permissions="permissions"
        />
        <PromotionPlans
          v-if="selectedSideTab == 'plans'"
          :permissions="permissions"
        />
      </template>
    </RightSideNavigation>
  </div>
</template>
<script>
import PromotionPlans from "./PromotionPlans.vue";
import PromotionAndDiscount from "./PromotionAndDiscount.vue";
import RightSideNavigation from "../utils/RightSideNavigation.vue";

import { mapGetters } from "vuex";

export default {
  name: "PromotionLibrary",
  components: {
    PromotionPlans,
    PromotionAndDiscount,
    RightSideNavigation,
  },
  props: {
    showTertiary: {
      type: Boolean,
      required: false,
      default: true,
    },
    permissions: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      selectedSideTab: "promotions",
      rightSideMenuItems: [
        {
          title: "Promotions & Discounts",
          id: "promotions",
        },
        {
          title: "Promotion Plans",
          id: "plans",
        },
      ],
    };
  },
  async mounted() {},
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
    }),
  },
  watch: {},
  beforeDestroy() {},
  methods: {},
};
</script>
