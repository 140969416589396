<template>
  <div>
    <!-- <DescriptiveTagForm ref="tags"  v-model="tags" /> -->
    <hb-form
      label="Name"
      description="Provide a name for promotion plan."
      required
      full
    >
      <small class="hb-text-light">
        This name will be displayed on the website. We recommend short and
        concise names.
      </small>
      <hb-text-field
        class="mt-1"
        v-validate="'required|max:50'"
        data-vv-name="name"
        data-vv-as="Name"
        :error="errors.collect('name').length > 0"
        v-model.trim="name"
        placeholder="Enter Name"
      >
      </hb-text-field>
    </hb-form>
    <hb-form
      label="Description"
      description="Provide a description for promotion plan."
      full
    >
      <HbTextarea
        :rows="2"
        v-model="description"
        placeholder="Enter Description"
      />
    </hb-form>
  </div>
</template>
<script>
// import DescriptiveTagForm from "../utils/DescriptiveTagForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "CreatePlanDetails",
  // components: { DescriptiveTagForm },
  props: {
    stepData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      name: "",
      description: "",
      statusCompleted: false,
    };
  },
  computed: {
    ...mapGetters({
      getActivePromotions: "revManStore/getActivePromotions",
      promoPlans: "revManStore/promotionPlans",
    }),
    activePromotions() {
      return this.getActivePromotions.filter(
        (promo) => promo?.label === "promotion"
      );
    },
  },
  methods: {
    async validateName() {
      await this.$validator.validateAll().then((valid) => {
        if (!valid) this.statusCompleted = false;
        else {
          const nameExists = (this.promoPlans ?? []).some(
            (plan) =>
              this.stepData?.id !== plan.id &&
              plan.name?.toLowerCase() === this.name.toLowerCase()
          );
          if (nameExists) {
            let msg = `A Promotion Plan already exists with the name.`;
            this.$validator.errors.add({
              field: "name",
              msg,
            });
          }
          this.statusCompleted = !nameExists;
        }
      });
    },

    // async validateDescriptiveTags() {
    //   if (!this.tags?.length) {
    //     this.$validator.errors.add({
    //       field: "tags",
    //       msg: "Descriptive Tags field is required. Choose atleast one tag.",
    //     });
    //     this.$refs.tags.isError = true;
    //   } else this.$refs.tags.isError = false;
    // },

    async submit() {
      this.$validator.errors.clear();
      await this.validateName();
      let data = {
        status: this.statusCompleted,
        validatedData: {
          name: this.name,
          description: this.description,
        },
      };
      return data;
    },

    resetData() {
      this.$validator.errors.clear();
      this.name = "";
      this.description = "";
    },
  },

  watch: {
    stepData: {
      handler(value) {
        this.name = value.name ?? ""
        this.description = value.description ?? ""
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
<style lang="scss" scoped></style>
