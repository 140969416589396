<script type="text/babel">
import { EventBus } from "../../../EventBus.js";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "PromotionManagementByPropertyActions",
  props: {
    propertyId: {
      type: String,
      default: "",
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    items() {
      let spacegroup_status =
        this.params.data.spacegroup_promotion_management_active;
      let statusLabel = {
        true: "Enable Promotion Management",
        false: "Disable Promotion Management",
      }[!Boolean(spacegroup_status)];
      let hasAssignPermission = this.permissions.assign_promotions;
      return [
        ...(hasAssignPermission
          ? [
              {
                text: "Assign In-Store & Online Promotion",
                event: "assignPromoInstore",
              },
              {
                text: "Assign Online Only Promotion",
                event: "assignPromoOnline",
              },
            ]
          : []),
        {
          text: "View Spaces",
          event: "viewSpaces",
        },
        {
          text: statusLabel,
          event: "toggleProfileStatus",
        },
      ];
    },
  },
  methods: {
    ...mapMutations({
      setSpaceData: "revManStore/SET_SPACE_DATA",
    }),
    triggerEvent(event) {
      if (event == "viewSpaces") {
        this.setSpaceData({
          ...this.params.data,
          ...{ propertyId: this.propertyId },
        });
      } else
        EventBus.$emit("promotionManagementEvents", {
          event,
          data: this.params.data,
        });
    },
  },
};
</script>

<template>
  <div>
    <!-- Populate Menu items -->
    <v-card class="mx-auto" max-width="500">
      <v-list>
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in items"
            @click="triggerEvent(item.event)"
            :key="i"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    <!-- Populate Menu items -->
  </div>
</template>
