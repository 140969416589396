<template>
  <hb-modal
    size="medium"
    v-model="removeDiscount"
    title="Confirm Delete Discount"
    @close="removeDiscount=false"
    confirmation
  >
    <template v-slot:content>
      <div class="px-4 py-2">
        <div class="pb-2">
          <span
            >You are about delete the following discount from
            {{ getPropertyName }}:</span
          >
        </div>
        <div class="pb-2">
          <span class="hb-font-body-medium">{{ selectedDiscount?.name }}</span>
        </div>
        <div>Are you sure you want to continue?</div>
      </div>
    </template>
    <template v-slot:right-actions>
      <hb-btn :loading="loading" @click="remove">Delete</hb-btn>
    </template>
  </hb-modal>
</template>
<script>
import api from "../../../assets/api";
import { notificationMixin } from "../../../mixins/notificationMixin";
import { mapGetters } from "vuex";

export default {
  name: "RemovePropertyDiscount",
  mixins: [notificationMixin],
  props: {
    property: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      removeDiscount: false,
      selectedDiscount: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
    }),
    getPropertyName() {
      let propertyDetails = this.properties.find(
        (property) => property.id === this.property
      );
      return propertyDetails?.name?.split('-')[0];
    },
  },
  methods: {
    show(discount) {
      this.removeDiscount = true;
      this.selectedDiscount = discount;
    },
    async remove() {
      this.loading = true;
      try {
        let url = api.getPropertyDiscountManagementUrl(this.property);
        await api.delete(this, url, this.selectedDiscount?.id);
        this.showMessageNotification({
          type: "success",
          description: "You have successfully removed a discount",
        });
        this.removeDiscount = false;
        this.$emit("discountRemoved");
      } catch (error) {
        this.showMessageNotification({
          type: "error",
          description: error,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
