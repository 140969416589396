<template>
  <div :class="cautionBar ? 'pt-4' : 'pt-1'">
    <!-- component to show a notification message note in caution bar   -->
    <template v-if="showPromotionPlans">
      <hb-notification
        v-model="cautionBar"
        title="Note:"
        type="caution"
        not-dismissable
      >
        <ul class="notification-bar">
          <li>
            Libraries are set up on a company level. Changes may affect multiple
            properties.
          </li>
          <li>
            Changes made to any Promotion plans will only take effect after
            midnight.
          </li>
        </ul>
      </hb-notification>

      <div class="pt-3">
        <div class="ml-auto d-flex promo-plan-heads">
          <hb-text-field
            v-model="searchkey"
            search
            full
            placeholder="Search Plans"
            :disabled="noPromoPlans"
            @input="onInput"
            class="pb-4"
            style="min-width: 272px"
          />
          <hb-btn
            class="pl-4 pb-4"
            @click="addEditPromotionPlan"
            color="secondary"
          >
            Create New Promo Plan
          </hb-btn>
        </div>
      </div>
      <div class="pb-4">
        <div v-if="plansLoading" class="promotion-plans-field pl-2 pt-2">
          <span v-for="n in 8" :key="n" class="loader-card">
            <v-skeleton-loader
              type="card-heading, list-item-two-line, image, image"
            />
          </span>
        </div>
        <div v-else-if="showEmptySection">
          <hb-empty-state
            :message="planPlaceholderMsg"
            :showBtn="showAddButton"
            class="mt-4"
            btnTxt="Create New Promo Plan"
            @clicked="addEditPromotionPlan"
          />
        </div>
        <div v-else class="promotion-plans-field">
          <promo-plan-card
            v-for="(plan, index) in plansList"
            :key="index + plan.name"
            :id="index + plan.name"
            :promo="plan"
            :edit-permission="permissions.manage_promotion_plans"
          />
          <div class="create-plan-wrapper d-flex justify-center align-center">
            <div class="create-plan-card-placeholder-wrapper">
              <div class="hb-text-night-light"></div>
              <hb-btn class="ma-2" @click="addEditPromotionPlan">
                Create New Promo Plan
              </hb-btn>
            </div>
          </div>
        </div>
      </div>
      <create-promotion-plan
        ref="createPromotionPlan"
        @success="refreshPlans"
      />
      <hb-modal
        v-model="permissionError"
        size="large"
        title="Unable to Create New Promo Plan"
        confirmation
        @close="permissionError = false"
        :footer-off="true"
      >
        <template v-slot:content>
          <div class="pa-4">
            <div class="pb-2">
              The signed in account does not have permission to
              <strong>Create New Promo Plan</strong>, contact your administrator
              to enable permission.
            </div>
          </div>
        </template>
      </hb-modal>
    </template>
    <hb-empty-state v-else :message="getEmptyTextMsg" />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";

import CreatePromotionPlan from "./CreatePromotionPlan.vue";
import PromoPlanCard from "./PromoPlanCard.vue";

export default {
  name: "PromotionPlans",
  components: {
    CreatePromotionPlan,
    PromoPlanCard,
  },
  props: {
    permissions: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      permissionError: false,
      cautionBar: true,
      guideBar: [],
      searchkey: "",
      plansList: [],
      plansLoading: false,
      timeout: null,
    };
  },
  mounted() {
    this.plansList = this.promoPlans;
  },
  created() {
    this.fetchPromotionPlans();
  },
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
      promoPlans: "revManStore/promotionPlans",
      selectedProperty: "revManStore/getSelectedProperty",
      propertyPromotionSettings: "revManStore/getPropertyPromotionSettings",
    }),
    ...mapState({
      hasAutomationEnabled: (state) => state.revManStore.promotion.automation,
    }),
    showPromotionPlans() {
      return this.permissions?.view_promotion_plans
        ? this.hasAutomationEnabled
          ? this.selectedProperty
            ? this.propertyPromotionSettings?.active &&
              this.propertyPromotionSettings?.automation_enabled_by_admin
            : true
          : false
        : false;
    },
    getEmptyTextMsg() {
      let text = "Promotion Management is not enabled for this Property";
      if (
        !this.propertyPromotionSettings.automation_enabled_by_admin ||
        !this.permissions.view_promotion_plans ||
        !this.hasAutomationEnabled
      )
        text =
          "You do not have the permission to access this page. Please contact the Admin.";
      return text;
    },
    planPlaceholderMsg() {
      let msg = "";
      if (this.noPromoPlans)
        msg = "Let us add a default promotion plan for easier use.";
      return msg;
    },
    showAddButton() {
      return this.noPromoPlans;
    },
    showEmptySection() {
      return this.noPromoPlans;
    },
    noPromoPlans() {
      return !(this.promoPlans && this.promoPlans.length);
    },
  },
  watch: {
    promoPlans: {
      immediate: true,
      handler() {
        this.plansList = this.promoPlans;
      },
      deep: true,
    },
  },
  beforeDestroy() {},
  methods: {
    ...mapActions({
      fetchPromotionPlans: "revManStore/fetchPromotionPlans",
    }),
    addEditPromotionPlan() {
      if (this.permissions.manage_promotion_plans)
        this.$refs?.createPromotionPlan?.show();
      else this.permissionError = true;
    },
    onInput() {
      clearTimeout(this.timeout);
      this.plansLoading = true;
      if (!this.searchkey) {
        this.plansList = this.promoPlans;
        this.plansLoading = false;
      } else {
        this.timeout = setTimeout(() => {
          this.searchPlan();
          this.plansLoading = false;
        }, 1000);
      }
    },
    searchPlan() {
      if (this.searchkey) {
        this.plansList = this.promoPlans.filter((plan) =>
          plan.name.toLowerCase().includes(this.searchkey.toLowerCase())
        );
      }
    },
    refreshPlans() {
      this.fetchPromotionPlans();
    },
  },
};
</script>
<style lang="scss" scoped>
.promo-plan-heads {
  justify-content: flex-end;
  flex-wrap: wrap;
}
.promotion-plans-field {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  @media (max-width: 2053px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1556px) {
    grid-template-columns: 1fr;
  }
}
.notification-bar {
  list-style: disc;
}
.create-plan-wrapper {
  min-height: 447px;
  border: 1px dashed #c4cdd5 !important;
  background-color: transparent !important;
  border-radius: 4px;

  .create-plan-card-placeholder-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
